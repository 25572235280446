<template>
  <div id="exec-bios-index">
    <h1 class="page-title">Leadership Team</h1>
    <div class="execs">
      <router-link
        :to="{ name: 'exec-bios-view', params: { id: bio.id } }"
        class="exec"
        v-for="bio in bios"
        :key="bio.id"
      >
        <div class="image">
          <HfaImage :name="bio.id" path="assets/exec-bios" />
        </div>
        <h2>{{ bio.name }}</h2>
        <h3>{{ bio.position }}</h3>
      </router-link>
    </div>
  </div>
</template>

<script>
import HfaImage from "@/components/HfaImage.vue";

export default {
  name: "ExecBiosIndex",
  components: {
    HfaImage
  },
  metaInfo: function() {
    return {
      title: " - Leadership Team",
      meta: [
        {
          name: "description",
          content: "Harry Fox Leadership Team"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    bios: function() {
      return this.$store.state.executives.bios;
    }
  }
};
</script>

<style lang="scss">
#exec-bios-index {
  h1 {
    color: #fff;
    margin-bottom: 15px;

    @media (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .execs {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    flex-wrap: wrap;
    row-gap: 100px;

    .exec {
      width: 95%;
      background-color: #fff;
      border-radius: 8px;
      padding-bottom: 40px;
      box-shadow: 0 22px 22px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .image {
        overflow: hidden;
        min-height: 330px;
        border-radius: 8px 8px 0 0;
        margin: -10px;

        img {
          display: block;
          width: 100%;
        }
      }
      h2 {
        font-family: "Antic Slab", serif;
        text-align: center;
        font-size: 19px;
        font-weight: 400;
      }
      h3 {
        font-family: "Fjalla One", sans-serif;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }

    @media (min-width: $desktop) {
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 100px;
      margin-left: -20px;

      .exec {
        width: 25vw;
        margin: 0;

        .image {
          height: auto;
        }
      }
    }
  }
}
</style>
